// Known error code
export const ERROR_CODES = {
  LoginFailed: 'PINT_1001',
  EmailAlreadyUsed: 'PINT_1002',
  UserNotVerified: 'PINT_1003',
  TokenError: 'PINT_1004',
  ExistedEmal: 'PINT_1005',
  TooMuchAppointmentsOnSameDay: 'PINT_1037'
};

// All api error
export const API_ERRORS = {
  [ERROR_CODES.LoginFailed]: {
    message: 'Đăng nhập thất bại'
  },
  [ERROR_CODES.EmailAlreadyUsed]: {
    message: 'Email đã được sử dụng'
  },
  [ERROR_CODES.TokenError]: {
    message: 'Lỗi token'
  },
  [ERROR_CODES.InvalidUser]: {
    message: 'Người dùng không hợp lệ'
  },
  [ERROR_CODES.TooMuchAppointmentsOnSameDay]: {
    message: 'Bạn đã có 1 lịch khám, vui lòng hoàn thành để đặt lịch mới'
  }
};

export const getErrorMessage = (error, defaultMessage = 'Unknown Error') => {
  if (error?.response?.metadata) {
    return API_ERRORS[error.response.metadata.messages[0].code]?.message;
  }
  return error.message || defaultMessage;
};
