import React, { useEffect, useState } from 'react';
import { Typography, Grid, Container, Link } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginMB } from 'src/redux/slice/authSlice';

import { getErrorMessage } from '@api/handleApiError';
import { useToast } from '@context/toastContext';
import { STORAGE_KEYS, Storage } from '@utils/storage';
import { BookingStepId } from '@utils/constants';
import { authLoginToken } from '@api/main';
import Logo from '@components/icons/Logo';
import Button from '@components/Button';

const Welcome = ({ setData, setCurrentStep }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const showToast = useToast();

  const [searchParams] = useSearchParams();

  useEffect(async () => {
    try {
      const loginToken = searchParams.get('loginToken');
      if (!loginToken || isLoggedIn) return;
      const result = await authLoginToken(loginToken);
      if (!result?.metadata || !result?.metadata.success) return;
      const { token, user } = result.data;
      Storage.setItem(STORAGE_KEYS.token, token);
      Storage.setItem(STORAGE_KEYS.userData, user);
      dispatch(loginMB());
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  }, [searchParams, isLoggedIn]);

  const nextStep = () => {
    setData({});
    setCurrentStep(BookingStepId.selectClinic);
  };

  return (
    <Container>
      <Grid container direction="column" paddingTop="2.75rem" paddingX="1rem">
        <Logo marginBottom="2rem" display="inline-block" textAlign="center" />
        <Typography variant="h2" marginBottom="6px" color="textPrimary.main">
          Phòng khám 4.0
        </Typography>
        <Typography variant="h5" lineHeight="30px" color="textPrimary.main">
          Quản lý phòng khám hỗ trợ đặt lịch khám đa nền tảng một cách thuận tiện nhất, tối ưu nhất
        </Typography>
        <Link href="https://drcloud.vn/" variant="h5" color="primary" underline="hover" width="max-content">
          Tìm hiểu về Quản lý phòng khám
        </Link>
        <Button
          // disabled={!isLoggedIn}
          marginTop="2rem"
          paddingY="10px"
          content="Đặt lịch khám"
          handleClick={nextStep}
          innerIcon={<ArrowForwardIos className="icon-next" />}
        />
      </Grid>
    </Container>
  );
};

export default Welcome;
