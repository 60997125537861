import React, { useEffect, useState } from 'react';
import {
  Grid,
  Avatar,
  Typography,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  styled,
  CircularProgress,
  Box
} from '@mui/material';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

import { getClinicInfo, getDoctorSchedule } from '@api/main';
import { BookingStepId, SpecialtyDefault } from '@utils/constants';
import NextStepLayoutWrapper from '@components/NextStepLayoutWrapper';
import { FormSelect, FormInputText, FormDatePicker } from '@components/Input';
import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';

const CreateAppointment = ({ data, setData, setCurrentStep }) => {
  const { clinic } = data;
  const [clinicInfo, setClinicInfo] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [dateSchedules, setDateSchedules] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const showToast = useToast();

  const defaultValues = {
    doctorId: '',
    specialtyId: SpecialtyDefault.specialtyId,
    dateSchedule: moment(),
    startDateTimeUnix: '',
    symptom: ''
  };

  const createAppointmentSchema = yup.object().shape({
    specialtyId: yup.string().required().trim(),
    symptom: yup.string().required().max(128).trim(),
    doctorId: yup.string().required().trim(),
    dateSchedule: yup.date().required(),
    startDateTimeUnix: yup.number().required()
  });

  const { handleSubmit, control, watch, reset, getValues, setValue } = useForm({
    mode: 'all',
    resolver: yupResolver(createAppointmentSchema),
    defaultValues
  });

  const specialtyId = watch('specialtyId');
  const doctorId = watch('doctorId');
  const startDateTimeUnix = watch('startDateTimeUnix');

  const onSubmit = async (value) => {
    const specialty = [SpecialtyDefault, ...(clinicInfo?.specialties || [])].find(
      (el) => el.specialtyId === value.specialtyId
    )?.specialtyName;
    const doctorName = doctors.find((el) => el.doctorId === value.doctorId)?.doctorName;
    const scheduleId = dateSchedules.find((el) =>
      moment(el?.occurrenceStartUnix).isSame(watch('dateSchedule'), 'day')
    )?.scheduleId;
    const currentValue = _.pick(value, ['doctorId', 'specialtyId', 'symptom', 'startDateTimeUnix']);
    setData((currentData) => ({
      ...currentData,
      ...currentValue,
      specialty,
      doctorName,
      scheduleId
    }));
    setCurrentStep(BookingStepId.confirmAppointment);
  };

  useEffect(async () => {
    try {
      if (!data?.clinicId) return;
      const result = await getClinicInfo(data?.clinicId);
      if (!result?.metadata || !result?.metadata.success) return;
      setClinicInfo(result.data);

      if (!!data?.scheduleId) {
        reset({
          ...defaultValues,
          ...{
            doctorId: data.doctorId,
            specialtyId: data.specialtyId,
            dateSchedule: moment(),
            startDateTimeUnix: data.startDateTimeUnix,
            symptom: data.symptom
          }
        });
        return;
      }
      setDoctors(result.data?.doctors);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  }, [data]);

  useEffect(async () => {
    if (!!clinicInfo && !!clinicInfo.doctors) {
      if (specialtyId === SpecialtyDefault.specialtyId) {
        setDoctors(clinicInfo.doctors);
        if (!!data?.scheduleId) return;
        setValue('doctorId', clinicInfo.doctors[0].doctorId);
      } else {
        const listDoctor = (clinicInfo?.doctors || []).filter((el) => {
          return specialtyId === el.specialtyId;
        });
        setDoctors(listDoctor);
      }
    }
  }, [specialtyId, data, clinicInfo]);

  useEffect(async () => {
    try {
      setSchedules([]);
      setDateSchedules([]);
      setValue('dateSchedule', moment());
      if (!doctorId) return;
      const result = await getDoctorSchedule(clinic?.clinicId, {
        doctorId,
        locationId: clinic.locationId
      });
      if (!result?.metadata || !result?.metadata.success) return;
      if (result.data.length > 0) {
        const res = result.data.sort((a, b) => a.occurrenceStartUnix - b.occurrenceStartUnix);
        setValue('dateSchedule', moment(res[0].occurrenceStartUnix));
        setDateSchedules(res);
      }
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  }, [doctorId]);

  useEffect(async () => {
    if (!getValues('dateSchedule') || dateSchedules.length === 0) return;
    const currentSchedule = dateSchedules.find((el) =>
      moment(el?.occurrenceStartUnix).isSame(getValues('dateSchedule'), 'day')
    );
    setSchedules(currentSchedule || []);
  }, [watch('dateSchedule'), dateSchedules]);

  useEffect(() => {
    if (!doctors) return;
    const index = doctors.findIndex((el) => el?.doctorId === doctorId);
    if (index !== -1) return;
    setValue('doctorId', doctors[0]?.doctorId);
  }, [doctors, specialtyId]);

  return (
    <NextStepLayoutWrapper
      headerContent="Đặt lịch khám"
      prevStep={() => setCurrentStep(BookingStepId.selectPatient)}
      nextStep={handleSubmit(onSubmit)}
      isNextStep={!!startDateTimeUnix}
    >
      <FormProvider>
        <Box
          sx={{ display: 'flex', p: '1em', alignItems: 'center' }}
          borderBottom="1px solid"
          borderColor="divider.main"
          item
          xs={12}
        >
          <Avatar
            sx={{ width: 48, height: 48, marginRight: '12px' }}
            src={clinic?.logo?.fileUrl}
            alt={clinic?.clinicName}
          />
          <Box component="div">
            <Typography variant="h3">{clinic?.clinicName}</Typography>
            <Typography color="grey.700" variant="h6" fontWeight="600">
              {clinic?.address}
            </Typography>
          </Box>
        </Box>
        <Grid container sx={{ p: '1em' }} spacing={2}>
          {/* Form */}
          <Grid item xs={12}>
            <FormSelect
              name="specialtyId"
              label="Chọn chuyên khoa"
              control={control}
              options={[SpecialtyDefault, ...(clinicInfo?.specialties || [])]}
              genderLabel={(el) => el.specialtyName}
              genderValue={(el) => el.specialtyId}
              isRequired
              hasBorder
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              hasBorder
              isRequired
              name="symptom"
              label="Lí do khám"
              placeholder="Nhập lí do khám của bạn"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="doctorId"
              label="Chọn bác sĩ"
              control={control}
              options={[...doctors] || []}
              genderLabel={(el) => el.doctorName}
              genderValue={(el) => el.doctorId}
              isRequired
              hasBorder
            />
          </Grid>
          <Grid item xs={12}>
            <FormDatePicker
              name="dateSchedule"
              label="Chọn ngày khám"
              control={control}
              isRequired
              minDate={moment(dateSchedules[0]?.occurrenceStartUnix)}
              maxDate={moment(dateSchedules[dateSchedules?.length - 1]?.occurrenceStartUnix)}
              hasBorder
            />
          </Grid>
          <Grid item xs={12}>
            {!!doctorId && dateSchedules.length > 0 && schedules === null && (
              <CircularProgress
                style={{
                  display: 'block',
                  margin: '20px auto'
                }}
              />
            )}

            {schedules?.slots?.length > 0 ? (
              <Controller
                name="startDateTimeUnix"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl required style={{ width: '100%' }}>
                    <RadioGroup
                      onChange={(i) => onChange(Number(i.target.value))}
                      value={value}
                      style={{ display: 'flex', rowGap: '1rem', columnGap: '12px', flexDirection: 'row' }}
                    >
                      {schedules?.slots?.map((el) => (
                        <FormControlLabelSchedule
                          key={el}
                          value={el}
                          label={moment(el).format('HH:mm')}
                          control={<Radio />}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
              />
            ) : (
              <Box
                sx={{
                  backgroundColor: 'divider.main',
                  padding: '16.5px 14px 16.5px 14px',
                  borderRadius: '10px',
                  textAlign: 'center'
                }}
                component="div"
              >
                <Typography sx={{ color: 'gray' }}>Ngày bạn chọn không có lịch của bác sĩ.</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </NextStepLayoutWrapper>
  );
};

const FormControlLabelSchedule = styled(FormControlLabel)(
  ({ theme }) => `
  width: calc(33.33% - 8px);
  margin: 0;
  .MuiRadio-root{
    display: none;
  }
  .MuiTypography-root {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid ${theme.palette.textPrimary.main};
    color: ${theme.palette.textPrimary.main};
  }
  .Mui-checked + .MuiTypography-root{
    border-color: transparent;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.white.main};
  }
`
);

export default CreateAppointment;
